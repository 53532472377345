import { useEffect } from "react";
import { PROCESS_DATA } from "../../constants/routes/api-routes";
import useMutate from "./useMutate";
import { useHistoryStore } from "../../store/historyStore";
import compressData from "../functions/compressData";
import { useFlowStore } from "../../store/flowStore";
import { useVariableStore } from "../../store/variableStore";
import { projectSettingsDefaults } from "../../constants/projectSettingsDefaults";

const useUpdateProject = () => {
  const {
    mutate: updateProjectMutation,
    isSuccess,
    isError,
    data,
  } = useMutate(
    "PUT",
    [PROCESS_DATA, localStorage.getItem("projectID")],
    `${PROCESS_DATA}/${localStorage.getItem("projectID")}/`
  );

  const historyStore = useHistoryStore();
  const variableStore = useVariableStore();

  const updateProject = (
    _events,
    isCustomData,
    isHistory = false,
    historyTitle
  ) => {
    if (
      localStorage.getItem("projectID") &&
      localStorage.getItem("projectData")
    ) {
      const _eventsStore = useFlowStore.getState().events;
      for (const key in _eventsStore) {
        if (Object.prototype.hasOwnProperty.call(_eventsStore, key)) {
          const element = _eventsStore[key];
          const _nodes = [...element.nodes];
          const _nodesData = [...element.nodesData];
          const _edges = [...element.edges];
          _nodesData.forEach((_node) => {
            if (_node?.data?.loading && _nodes.find((e) => e.id === _node.id)) {
              _node.data.loading = false;
            }
          });
          const _finalEdges = _edges.filter(
            (edge) =>
              _nodes.findIndex((n) => n.id === edge.source) > -1 &&
              _nodes.findIndex((n) => n.id === edge.target) > -1
          );

          element.edges = _finalEdges;
          element.nodesData = _nodesData;
        }
      }
      const _sendingData = isCustomData
        ? _events
        : {
            events: _eventsStore,
            variables: useVariableStore.getState().Variables ?? [],
            constants: useVariableStore.getState().Constants ?? [],
            oldValues: useVariableStore.getState().oldValues ?? [],
            project_options: localStorage.getItem("projectSettings")
              ? JSON.parse(localStorage.getItem("projectSettings"))
              : projectSettingsDefaults,
          };
      const _project = JSON.parse(localStorage.getItem("projectData"));
      _project.json_file = JSON.stringify({
        data: _sendingData,
        selected_name: localStorage.getItem("projectName"),
        name_by_user: localStorage.getItem("name_by_user"),
        highestIndex: localStorage.getItem("highestIndex"),
        status: "selected",
      });
      _project.project_detail_levels = [];
      let _history = [
        ...useHistoryStore
          .getState()
          .histories?.sort((a, b) =>
            new Date(a?.assigned_name.split("*")[1] ?? undefined).getTime() >
            new Date(b?.assigned_name.split("*")[1] ?? undefined).getTime()
              ? 1
              : -1
          ),
      ];
      const selectedIndex = _history.findIndex(
        (e) => e?.assigned_name === useHistoryStore.getState().selectedHistoryId
      );
      if (selectedIndex > -1) {
        _history = _history.slice(0, selectedIndex + 1);
      }
      if (isHistory) {
        historyStore.addHistory({
          json_file: _project.json_file,
          historyTitle: historyTitle,
        });

        if (_history.length >= 16) {
          _history.shift();
        }
        _history.forEach((_hist) => {
          delete _hist.id;
        });

        const _historyProject = _project.json_file;
        const _time = new Date().toLocaleString();
        const _historyLength = _history.filter(
          (e) => JSON.parse(e.json_file)?.status !== "inActive"
        ).length;
        _history.push({
          project_detail: Number(localStorage.getItem("projectID")),
          assigned_name: historyTitle
            ? `${_historyLength + 1}-${historyTitle}*${_time}`
            : `${_historyLength + 1}-history*${_time}`,
          json_file: _historyProject,
        });
        _project.project_detail_levels = _history.filter((e) => {
          const _status = JSON.parse(e.json_file)?.status;
          if (!e.json_file?.compressedData) {
            e.json_file = JSON.stringify({
              compressedData: compressData(e.json_file),
            });
          }
          return _status !== "inActive";
        });
      } else {
        _project.project_detail_levels = _history;
      }
      _project.name_by_user = localStorage.getItem("name_by_user");
      localStorage.setItem("projectData", JSON.stringify(_project));
      delete _project?.user_related;
      updateProjectMutation(_project);
    }
  };
  useEffect(() => {
    if (data) {
      localStorage.setItem("projectData", JSON.stringify(data?.data));
    }
  }, [data]);

  return { updateProject, isSuccess, isError };
};
export default useUpdateProject;
