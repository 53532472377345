import { useMutation } from "@tanstack/react-query";
import ApiClient from "../../API";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
const useMutate = (
  method,
  queryKey,
  endpoint,
  params,
  reactQueryOptions,
  axiosOptions,
  extraAxiosFeatures
) => {
  const mutateFunction = async (data) => {
    try {
      const isFormData = data instanceof FormData;
      const config = {
        method: method,
        url: endpoint,
        data: isFormData ? data : JSON.stringify(data),
        params: { ...params },
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
          Authorization: Cookies.get("token")
            ? `Bearer ${Cookies.get("token")}`
            : null,
          ...(isFormData ? {} : { "Content-Type": "application/json" }),
        },
        transformRequest: [
          (data, headers) => {
            if (isFormData) {
              return data;
            }
            return data;
          },
        ],
        ...axiosOptions,
        ...extraAxiosFeatures,
      };

      return await ApiClient(config);
    } catch (error) {
      toast.error("Something Went Wrong!");
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        const cookiesToRemove = [
          "token",
          "role",
          "refreshToken",
          "tokenExpireTime",
        ];
        cookiesToRemove.forEach((cookieName) => {
          Cookies.remove(cookieName, {
            domain: process.env.REACT_APP_MAIN_DOMAIN,
          });
          Cookies.remove(cookieName);
        });

        window.location.reload();
        throw error;
      }
      throw error;
    }
  };

  return useMutation({
    queryKey: [queryKey],
    mutationFn: mutateFunction,
    ...reactQueryOptions,
  });
};

export default useMutate;
