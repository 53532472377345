import SideBar from "../../../Components/Sidebar";
import FlowCanvas from "../../../Components/FlowCanvas";
import EventsBar from "../../../Components/EventsBar";
import { useState, useRef, useEffect } from "react";
import LoadProjectModal from "../../../Components/ProjectModals/LoadProjectModal";
import NewProjectModal from "../../../Components/ProjectModals/NewProjectModal";
import HistorySection from "../../../Components/HistorySection";
import StyledHome from "./StyledHome";
import Cookies from "js-cookie";
import { useVariableStore } from "../../../store/variableStore";
import { useFlowStore } from "../../../store/flowStore";
import { useNavigate } from "react-router-dom";
import { LOGIN_ROUTE } from "../../../constants/routes/app-routes";
import useMutate from "../../../utils/hooks/useMutate";
import useFetch from "../../../utils/hooks/useFetch";
import {
  UPLOAD_PROJECT,
  PROCESS_DATA,
} from "../../../constants/routes/api-routes";
import { toast } from "react-toastify";
import { useHistoryStore } from "../../../store/historyStore";
import { v4 as uuidv4 } from "uuid";
import { projectSettingsDefaults } from "../../../constants/projectSettingsDefaults";

const Home = () => {
  const [reRender, setRerender] = useState(false);
  const [loadProjectModal, setLoadProjectModal] = useState(false);
  const [newProjectModal, setNewProjectModal] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const { resetStore } = useVariableStore();
  const { resetStore: flowReset } = useFlowStore();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const flowStore = useFlowStore();
  const historyStore = useHistoryStore();
  const variableStore = useVariableStore();
  const { mutate: uploadProject, data: uploadProjectData } = useMutate(
    "POST",
    ["uploadProject"],
    UPLOAD_PROJECT
  );
  const { mutate: createProject, data: createProjectData } = useMutate(
    "POST",
    ["createProject"],
    PROCESS_DATA
  );

  const {
    data: projectData,
    refetch: projectRefetch,
    isLoading: projectLoading,
  } = useFetch(
    "GET",
    [PROCESS_DATA, localStorage.getItem("projectName")],
    PROCESS_DATA + "/search",
    { selected_name: localStorage.getItem("projectName") },
    null,
    { enabled: false }
  );

  const handleImportProject = async (event) => {
    const file = event.target.files[0];

    if (file) {
      console.log("Selected file:", file);
      if (file.size === 0) {
        toast.error("The selected file is empty. Please choose a valid file.");
        return;
      }

      setIsImporting(true);

      try {
        const formData = new FormData();
        formData.append("file", file);
        await uploadProject(formData);
        toast.success("Project imported successfully!");
      } catch (error) {
        console.error("Upload error:", error);
        toast.error("Failed to import project. Please try again.");
      } finally {
        setIsImporting(false);
      }
    } else {
      toast.error("No file selected. Please choose a file to import.");
    }
  };

  useEffect(() => {
    if (uploadProjectData) {
      const _data = uploadProjectData?.data;
      const _uuid = uuidv4();
      localStorage.setItem("projectData", JSON.stringify(_data));
      localStorage.setItem("name_by_user", _data?.name_by_user);
      const _projectOptions =
        _data?.data?.project_options || projectSettingsDefaults;
      localStorage.setItem("projectSettings", JSON.stringify(_projectOptions));

      let _parsedData = _data.data;
      if (_parsedData?.events) {
        for (const key in _parsedData?.events) {
          if (Object.hasOwnProperty.call(_parsedData?.events, key)) {
            const element = _parsedData?.events[key];
            element.nodesData.forEach((e) => {
              e.selected = false;
              e.dragging = false;
            });
          }
        }
        flowStore.setEvents(_parsedData?.events);
        flowStore.setSelectedEvent("on_tick");

        variableStore.setConstants(_parsedData?.constants ?? []);
        variableStore.setVariables(_parsedData?.variables ?? []);
        variableStore.setOldValues(_parsedData?.oldValues ?? []);

        localStorage.setItem("highestIndex", Number(_data?.highestIndex || 1));
      }
      const _sendingData = {
        events: _parsedData.events || {},
        variables: _parsedData.variables || [],
        constants: _parsedData.constants || [],
        oldValues: [],
        project_options: _projectOptions,
      };

      const _apiData = {
        data: _sendingData,
        selected_name: _uuid,
        name_by_user: _data?.name_by_user || "Imported Project",
        highestIndex: _data?.highestIndex || 1,
      };

      createProject(_apiData);
      localStorage.setItem("projectName", _uuid);
    }
  }, [uploadProjectData]);

  useEffect(() => {
    if (createProjectData) {
      projectRefetch();
    }
  }, [createProjectData]);

  useEffect(() => {
    if (projectData) {
      const _selectedProject = projectData?.data?.find(
        (item) => item?.selected_name === localStorage.getItem("projectName")
      );
      if (_selectedProject) {
        localStorage.setItem("projectID", _selectedProject?.id);
        localStorage.setItem("projectData", JSON.stringify(_selectedProject));
        window.location.reload();
      }
    }
  }, [projectData]);

  return (
    <StyledHome>
      <div className="App">
        <div className="topSection">
          {loadProjectModal && (
            <LoadProjectModal setModal={setLoadProjectModal} />
          )}
          {newProjectModal && <NewProjectModal setModal={setNewProjectModal} />}
          <div className="menuContainer">
            <div className="menuParent">
              <span className="menuTitle">Projects</span>
              <div className="menuModal">
                <button onClick={() => setNewProjectModal(true)}>
                  New Project
                </button>
                <button onClick={() => setLoadProjectModal(true)}>
                  Load Project
                </button>
                <button
                  onClick={() => fileInputRef.current.click()}
                  disabled={isImporting}
                >
                  {isImporting ? "Importing..." : "Import Project (.mq4)"}
                </button>
                <input
                  type="file"
                  ref={fileInputRef}
                  accept=".mq4"
                  style={{ display: "none" }}
                  onChange={handleImportProject}
                />
              </div>
            </div>
            <div className="menuParent">
              <div className="menuTitle">
                <span className="menuTitle">Profile</span>
                <div className="menuModal">
                  <button
                    onClick={() => {
                      localStorage.clear();
                      Cookies.remove("token", {
                        domain: process.env.REACT_APP_MAIN_DOMAIN,
                      });
                      Cookies.remove("role", {
                        domain: process.env.REACT_APP_MAIN_DOMAIN,
                      });
                      Cookies.remove("refreshToken", {
                        domain: process.env.REACT_APP_MAIN_DOMAIN,
                      });
                      Cookies.remove("tokenExpireTime", {
                        domain: process.env.REACT_APP_MAIN_DOMAIN,
                      });
                      Cookies.remove("token");
                      Cookies.remove("role");
                      Cookies.remove("refreshToken");
                      Cookies.remove("tokenExpireTime");
                      resetStore();
                      flowReset();
                      navigate(LOGIN_ROUTE);
                    }}
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
          <HistorySection reRender={reRender} setRerender={setRerender} />
          <EventsBar setRerender={setRerender} />
        </div>
        <div>
          <div className="mainWrapper">
            <div className="leftSection">
              <SideBar reRender={reRender} setRerender={setRerender} />
            </div>
            <div className="rightSection">
              <FlowCanvas reRender={reRender} setRerender={setRerender} />
            </div>
          </div>
        </div>
      </div>
    </StyledHome>
  );
};

export default Home;
